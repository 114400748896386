import { FiatTokenToAmount, TokenToAmount } from "src/constants/types";
export const REACT_APP_S3_BUCKET_URL_TEST =
  "https://wadzpay-app-bucket-privatechain-test.s3.me-south-1.amazonaws.com/";
console.log(process.env.NODE_ENV,"===")
export const REACT_APP_S3_BUCKET_URL_DEV =
  "https://wadzpay-app-bucket-privatechain-dev.s3.me-south-1.amazonaws.com/";
  export const s3Config = {
    bucketName: process.env.NODE_ENV === 'test'?"wadzpay-tf-app-bucket-privatechain-test":'wadzpay-app-bucket-privatechain-dev',
    region: "me-south-1",
    accessKeyId: process.env.NODE_ENV === 'test'?"AKIAR3T64BWVHHQXJLTH":'AKIAWG6SXYEE2ZMPWN7S',
    secretAccessKey: process.env.NODE_ENV === 'test'? "vWIjTwI0+u8KpnNhDEXevZbpcS1k+PjuMhtyeaWU":'zcAg26bUU691Zum9nUSOcZFod5HGWztON4yYEevm',
    //s3Url: 'https:/your-custom-s3-url.com/', /* optional */
  };
export enum EndpointKey {
  SEND_PHONE_OTP = "sendPhoneOTP",
  VERIFY_EMAIL_OTP_CREATE_USER = "verifyEmailOTPAndCreateUser",
  GET_USER = "getUser",
  VERIFY_PHONE_OTP = "verifyPhoneOTP",
  USER_DETAILS_SEND_EMAIL_OTP = "userDetailsAndEmailOTP",
  USER_BALANCES = "userBalances",
  MERCHANT_TRANSACTION = "merchantTransaction",
  MERCHANT_TRANSACTIONS = "merchantTransactions",
  REFUND_TRANSACTIONS = "refundTransactions",
  REFUND_ACCEPT_REJECT_TRANSACTIONS = "refundAcceptRejectTransactions",
  REFUND_INITIATE_WEBLINK = "refundInitiateWebLink",
  REFUND_SUBMIT_WITHAUTH = "refundSubmitFormWithAuth",
  EXCHANGE_RATES = "exchangeRates",
  USER_TRANSACTION = "userTransaction",
  USER_TRANSACTIONS = "userTransactions",
  MERCHANT_DETAILS = "merchantDetails",
  P2P_TRANSACTIONS = "p2pTransaction",
  GENERATE_API_KEY = "generateAPIKey",
  INVITE_USER = "inviteUser",
  MERCHANT = "merchant",
  USER_LIST = "userList",
  ENABLE_USER = "enableUser",
  DISABLE_USER = "disableUser",
  SUBMIT_REFUND_FORM_DATA = "submitRefundForm",
  GET_REFUND_FORM = "getRefundForm",
  REFUND_EXCHANGE_RATES = "refundExchangeRates",
  RECENT_PAYMENT = "recentPayment",
  ATM_WITHDRAW = "atmWithDraw",
  USER_VERIFY = "userVerify",
  USER_VERIFY_RESET = "userVerifyReset",
  REFUND_FORM_FIELDS = "refundFormFields",
  GET_AGGREGATOR_TREE = "getAggregatorTree",
  SAVE_AGGREGATOR_DETAILS = "saveAggregatorDetails",
  SAVE_AGGREGATOR_DRAFT = "saveAggregatorDraft",
  GET_AGGREGATOR_DETAILS = "getAggregatorDetails",
  GET_AGGREGATOR = "getAggregatorById",
  GET_INSTITUTION = "getInstitutionById",
  GET_MERCHANT_GROUP = "getMerchantGroupById",
  GET_MERCHANT = "getMerchantById",
  GET_SUB_MERCHANT = "getSubMerchantById",
  GET_OUTLET = "getOutletById",
  UPDATE_AGGREGATOR_DETAILS = "updateAggregatorDetails",
  UPDATE_AGGREGATOR_DRAFT = "updateAggregatorDraft",
  DELETE_AGGREGATOR = "deleteAggregator",
  ADD_CONVERSION_RATES = "addConversionRates",
  GET_INDUSTRY_TYPE_LIST = "getIndustryTypeList",
  GET_FIAT_CURRENCY_LIST = "getFiatCurrencyList",
  GET_INTITUTION_LIST = "getInstitutionList",
  CREATE_INSTITUTION = "createInstitution",
  SAVE_INSTITUTION = "saveInstitution",
  DELETE_INSTITUTION = "institutionDelete",
  UPDATE_INSTITUTION_DETAILS = "updateInstitutionDetails",
  UPDATE_INSTITUTION_DRAFT = "updateInstitutionDraft",
  CREATE_MERCHANT_GROUP = "createMerchantGroup",
  SAVE_MERCHANT_GROUP = "saveMerchantGroup",
  UPDATE_MERCHANT_GROUP = "updateMerchantGroup",
  UPDATE_MERCHANT_GROUP_DRAFT = "updateMerchantGroupDraft",
  DELETE_MERCHANT_GROUP = "merchantGroupDelete",
  GET_MERCHANT_GROUP_LIST = "getMerchantGroupList",
  CREATE_MERCHANT_ACQUIRER = "createMerchantAcquirer",
  SAVE_MERCHANT_ACQUIRER = "saveMerchantAcquirer",
  UPDATE_MERCHANT_ACQUIRER = "updateMerchantAcquirer",
  UPDATE_MERCHANT_ACQUIRER_DRAFT = "updateMerchantAcquirerDraft",
  CREATE_SUB_MERCHANT_ACQUIRER = "createSubMerchantAcquirer",
  SAVE_SUB_MERCHANT_ACQUIRER = "saveSubMerchantAcquirer",
  UPDATE_SUB_MERCHANT_ACQUIRER = "updateSubMerchantAcquirer",
  UPDATE_SUB_MERCHANT_ACQUIRER_DRAFT = "updateSubMerchantAcquirerDraft",

  GET_MERCHANT_ACQUIRER_LIST = "getMerchantAcquirerList",
  GET_SUB_MERCHANT_ACQUIRER_LIST = "getSubMerchantAcquirerList",
  DELETE_MERCHANT = "merchantDelete",
  DELETE_SUB_MERCHANT = "subMerchantDelete",
  CREATE_OUTLET = "createOutlet",
  SAVE_OUTLET = "saveOutlet",
  UPDATE_OUTLET_DRAFT = "updateOutletDraft",
  UPDATE_OUTLET = "updateOutlet",
  GET_OUTLET_LIST = "outletList",
  DELETE_OUTLET = "outletDelete",
  CREATE_POS = "createPos",
  UPDATE_POS = "updatePos",
  DELETE_POS = "deletePos",
  POS_LIST = "posList",
  POS_LIST_DETAILS = "posListDetails",
  LEVEL_LIST = "levelList",
  CREATE_LEVEL = "createLevel",
  UPDATE_LEVEL = "updateLevel",
  MODULE_LIST = "moduleList",
  MODULE_LIST_TREE = "moduleListTree",

  CREATE_MODULE = "createModule",
  UPDATE_MODULE = "updateModule",
  AGGREGATOR_UPLOAD = "uploadAggregator",
  INSTITUTION_UPLOAD = "uploadInstitution",
  CREATE_ROLE = "createRole",
  UPDATE_ROLE = "updateRole",
  ROLE_LIST = "roleList",
  USER_ROLE_LIST = "userRoleList",
  CREATE_USER = "createUser",
  UPDATE_USER = "updateUser",
  USER_MANAGEMENT_LIST = "userManagementList",
  GET_DEPARTMENTS = "getDepartments",
  GET_ROLES = "getRoles",
  GET_ROLES_BY_USERS = "getRolesByUsers",
  DE_ACTIVATE_USER_BY_USERID = "deActivateUser",
  GET_USER_BY_UUID = "getUserByUUID",
  RESET_PASSWORD = "resetPassword",
  LOGIN = "login",
  RESET_PASSWORD_LINK_BY_EMAIL = "resetPasswordLinkByEmail",
}

export const useApi: () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in EndpointKey]: (...args: any[]) => string;
} = () => {
  return {
    getUser: (query: string) => `user?${query}`,
    sendPhoneOTP: () => "user/registration/phone/",
    verifyPhoneOTP: () => "user/registration/phone/verify/",
    userDetailsAndEmailOTP: () => "user/registration/details/",
    verifyEmailOTPAndCreateUser: () => "user/registration/verify-and-create/",
    userBalances: () => "merchantDashboard/balances",
    merchantTransaction: (id: string) => `merchant/transactions/${id}`,
    merchantTransactions: (query: string) =>
      `merchant/transaction/search?${query}`,
    refundTransactions: (query: string) =>
      `merchant/refund/transactions?${query}`,
    refundAcceptRejectTransactions: () => "merchant/refund/approveAlgo",
    refundInitiateWebLink: () => "merchantDashboard/initiateWeblinkRefundAlgo",
    refundSubmitFormWithAuth: () =>
      "merchantDashboard/submitRefundFormAutoApprove",
    exchangeRates: (fiat: string) => `v1/config/exchangeRates?from=${fiat}`,
    userTransaction: (id: string) => `user/transactions/${id}`,
    userTransactions: (query: string) =>
      `merchantDashboard/transactions?${query}`,
    merchant: () => "merchantDashboard/admin/merchant/",
    p2pTransaction: () => "merchantDashboard/admin/p2p_transaction",
    generateAPIKey: () => "merchantDashboard/admin/api-key",
    inviteUser: () => "merchantDashboard/admin/invite/",
    merchantDetails: () => "merchantDashboard/merchantDetails",
    userList: () => "merchantDashboard/getAllUsersOfMerchant",
    enableUser: () => "merchantDashboard/admin/enable",
    disableUser: () => "merchantDashboard/admin/disable",
    submitRefundForm: () => "api/v1/pos/refundForm",
    getRefundForm: () => "api/v1/merchantDashboard/getRefundDetailsFromToken",
    refundExchangeRates: (query: string) =>
      `api/v1/merchantDashboard/exchangeRates?from=${query}`,
    recentPayment: () => "merchantDashboard/requestPayment",
    atmWithDraw: () => "bdo/withdraw/wallet",
    userVerify: (query: string) => `api/v1/userVerify?${query}`,
    userVerifyReset: (query: string) => `api/v1/userVerifyReset?${query}`,
    refundFormFields: (query: string) =>
      `api/v1/merchantDashboard/refundFormFields?formName=${query}`,
    getAggregatorTree: () => "merchant/acquirer/getAggregatorTree",
    saveAggregatorDetails: () => "merchant/acquirer/createAggregator",
    saveAggregatorDraft: () => "merchant/acquirer/saveAggregator",
    getAggregatorDetails: () => "merchant/acquirer/aggregatorList",
    getAggregatorById: (aggregatorId: string) =>
      `merchant/acquirer/aggregator/${aggregatorId}`,
    getInstitutionById: (institutionId: string) =>
      `merchant/acquirer/institution/${institutionId}`,
    getMerchantGroupById: (merchantGroupId: string) =>
      `merchant/acquirer/merchantGroup/${merchantGroupId}`,
    getMerchantById: (merchantId: string) =>
      `merchant/acquirer/merchant/${merchantId}`,
    getSubMerchantById: (subMerchantId: string) =>
      `merchant/acquirer/submerchant/${subMerchantId}`,
    getOutletById: (outletId: string) => `merchant/acquirer/outlet/${outletId}`,
    updateAggregatorDetails: () => "merchant/acquirer/aggregatorUpdate",
    updateAggregatorDraft: () => "merchant/acquirer/aggregatorDraftUpdate",
    deleteAggregator: () => "merchant/acquirer/aggregatorDelete",
    addConversionRates: () => "issuance/admin/addConversionRates",
    getIndustryTypeList: () => "issuance/getIndustryTypeList",
    getFiatCurrencyList: () => "issuance/getFiatCurrencyList",
    getInstitutionList: () => `merchant/acquirer/institutionList`,
    createInstitution: () => "merchant/acquirer/createInstitution",
    saveInstitution: () => "merchant/acquirer/saveInstitution",
    updateInstitutionDetails: () => "merchant/acquirer/institutionUpdate",
    updateInstitutionDraft: () => "merchant/acquirer/institutionDraft",
    institutionDelete: () => "merchant/acquirer/institutionDelete",
    createMerchantGroup: () => "merchant/acquirer/createMerchantGroup",
    saveMerchantGroup: () => "merchant/acquirer/saveMerchantGroup",
    updateMerchantGroup: () => "merchant/acquirer/merchantGroupUpdate",
    updateMerchantGroupDraft: () =>
      "merchant/acquirer/merchantGroupUpdateDraft",
    merchantGroupDelete: () => "merchant/acquirer/merchantGroupDelete",
    getMerchantGroupList: () => `merchant/acquirer/getMerchantGroupList`,
    createMerchantAcquirer: () => "merchant/acquirer/createMerchantAcquirer",
    saveMerchantAcquirer: () => "merchant/acquirer/saveMerchantAcquirer",
    updateMerchantAcquirer: () => "merchant/acquirer/merchantAcquirerUpdate",
    updateMerchantAcquirerDraft: () =>
      "merchant/acquirer/merchantAcquirerUpdateDraft",
    getMerchantAcquirerList: () => "merchant/acquirer/merchantAcquirerList",
    merchantDelete: () => "merchant/acquirer/merchantAcquirerDelete",
    createSubMerchantAcquirer: () =>
      "merchant/acquirer/createSubMerchantAcquirer",
    saveSubMerchantAcquirer: () => "merchant/acquirer/saveSubMerchantAcquirer",
    updateSubMerchantAcquirerDraft: () =>
      "merchant/acquirer/subMerchantUpdateDraft",
    updateSubMerchantAcquirer: () => "merchant/acquirer/subMerchantUpdate",
    getSubMerchantAcquirerList: () => "merchant/acquirer/subMerchantList",
    subMerchantDelete: () => "merchant/acquirer/subMerchantDelete",
    createOutlet: () => "merchant/acquirer/createOutlet",
    saveOutlet: () => "merchant/acquirer/saveOutlet",
    updateOutletDraft: () => "merchant/acquirer/updateOutletDraft",
    updateOutlet: () => "merchant/acquirer/updateOutlet",
    outletList: () => "merchant/acquirer/outletList",
    outletDelete: () => "merchant/acquirer/outletDelete",
    createPos: () => "merchant/acquirer/createPos",
    updatePos: () => "merchant/acquirer/updatePos",
    deletePos: () => "merchant/acquirer/deletePos",
    posList: () => `merchant/acquirer/getPosListByOutlet`,
    levelList: () => `merchant/role/levels`,
    createLevel: () => `merchant/role/createLevel`,
    updateLevel: () => `merchant/role/updateLevel`,
    moduleList: () => `merchant/role/moduleList`,
    moduleListTree: () => `merchant/role/moduleListCategories`,
    createModule: () => `merchant/role/createModule`,
    updateModule: () => `merchant/role/updateModule`,
    posListDetails: () => `merchant/acquirer/posList`,
    uploadAggregator: () => "/agg",
    uploadInstitution: () => "ins",
    createRole: () => "merchant/role/createRole",
    roleList: () => `merchant/role/rolesFiltersPaginationv2`,
    userRoleList: () => "user/role/getRolesByUsers",
    updateRole: () => "merchant/role/updateRole",
    createUser: () => "user/createUser",
    userManagementList: () => `user/userFiltersPagination`,
    updateUser: () => "user/updateUser",
    getDepartments: () => "department/fetchAll",
    getRoles: (query: string) => `role/getRoles?${query}`,
    getRolesByUsers: (query: string) => `user/role/getRolesByUsers?${query}`,
    deActivateUser: () => "user/deActivateUser",
    getUserByUUID: (query: string) => `api/v1/getUserByUUID?${query}`,
    resetPassword: () => "api/v1/setPassword",
    login: () => "api/v1/login",
    resetPasswordLinkByEmail: (query: string) =>
      `api/v1/resetPasswordLinkByEmail?${query}`,
  } as const;
};
export enum UrlUpload {
  AGGREGATOR_UPLOAD = "merchant/acquirer/upload-aggregator-csv",
  INSTITUTION_UPLOAD = "merchant/acquirer/upload-institution-csv",
  MERCHANTGROUP_UPLOAD = "merchant/acquirer/upload-merchantGroup-csv",
  MERCHANT_UPLOAD = "merchant/acquirer/upload-merchant-csv",
  SUBMERCHANT_UPLOAD = "merchant/acquirer/upload-submerchant-csv",
  OUTLET_UPLOAD = "merchant/acquirer/upload-outlet-csv",
}

export const calculateTimeLeft: (targetDate: Date) => number = (targetDate) => {
  const now = new Date();
  const difference = +targetDate - +now;
  return difference > 0 ? Math.floor((difference / 1000) % 60) : 0;
};

export const AssetFractionDigits: TokenToAmount = {
  BTC: 8,
  ETH: 8,
  USDT: 8,
  WTK: 8,
  USDC: 8,
  SART: 8,
};

export const FiatAssetFractionDigits: FiatTokenToAmount = {
  USD: 2,
  EUR: 2,
  INR: 1,
  IDR: 1,
  SGD: 2,
  GBP: 2,
  PKR: 1,
  PHP: 1,
  AED: 2,
  WTK: 1,
  USDC: 1,
  SAR: 1,
};

export const countries = [
  { name: "Afghanistan", code: "AF", phone: "+93" },
  { name: "Åland Islands", code: "AX", phone: "+358" },
  { name: "Albania", code: "AL", phone: "+355" },
  { name: "Algeria", code: "DZ", phone: "+213" },
  { name: "American Samoa", code: "AS", phone: "+1" },
  { name: "Andorra", code: "AD", phone: "+376" },
  { name: "Angola", code: "AO", phone: "+244" },
  { name: "Anguilla", code: "AI", phone: "+1" },
  { name: "Antarctica", code: "AQ", phone: "" },
  { name: "Antigua & Barbuda", code: "AG", phone: "+1" },
  { name: "Argentina", code: "AR", phone: "+54" },
  { name: "Armenia", code: "AM", phone: "+374" },
  { name: "Aruba", code: "AW", phone: "+297" },
  { name: "Australia", code: "AU", phone: "+61" },
  { name: "Austria", code: "AT", phone: "+43" },
  { name: "Azerbaijan", code: "AZ", phone: "+994" },
  { name: "Bahamas", code: "BS", phone: "+1" },
  { name: "Bahrain", code: "BH", phone: "+973" },
  { name: "Bangladesh", code: "BD", phone: "+880" },
  { name: "Barbados", code: "BB", phone: "+1" },
  { name: "Belarus", code: "BY", phone: "+375" },
  { name: "Belgium", code: "BE", phone: "+32" },
  { name: "Belize", code: "BZ", phone: "+501" },
  { name: "Benin", code: "BJ", phone: "+229" },
  { name: "Bermuda", code: "BM", phone: "+1" },
  { name: "Bhutan", code: "BT", phone: "+975" },
  { name: "Bolivia", code: "BO", phone: "+591" },
  { name: "Caribbean Netherlands", code: "BQ", phone: "+599" },
  { name: "Bosnia & Herzegovina", code: "BA", phone: "+387" },
  { name: "Botswana", code: "BW", phone: "+267" },
  { name: "Bouvet Island", code: "BV", phone: "" },
  { name: "Brazil", code: "BR", phone: "+55" },
  { name: "British Indian Ocean Territory", code: "IO", phone: "" },
  { name: "Brunei", code: "BN", phone: "+673" },
  { name: "Bulgaria", code: "BG", phone: "+359" },
  { name: "Burkina Faso", code: "BF", phone: "+226" },
  { name: "Burundi", code: "BI", phone: "+257" },
  { name: "Cambodia", code: "KH", phone: "+855" },
  { name: "Cameroon", code: "CM", phone: "+237" },
  { name: "Canada", code: "CA", phone: "+1" },
  { name: "Cape Verde", code: "CV", phone: "+238" },
  { name: "Cayman Islands", code: "KY", phone: "+1" },
  { name: "Central African Republic", code: "CF", phone: "+236" },
  { name: "Chad", code: "TD", phone: "+235" },
  { name: "Chile", code: "CL", phone: "+56" },
  { name: "China", code: "CN", phone: "+86" },
  { name: "Christmas Island", code: "CX", phone: "+61" },
  { name: "Cocos (Keeling) Islands", code: "CC", phone: "+61" },
  { name: "Colombia", code: "CO", phone: "+57" },
  { name: "Comoros", code: "KM", phone: "+269" },
  { name: "Congo - Brazzaville", code: "CG", phone: "+242" },
  { name: "Congo - Kinshasa", code: "CD", phone: "+243" },
  { name: "Cook Islands", code: "CK", phone: "+682" },
  { name: "Costa Rica", code: "CR", phone: "+506" },
  { name: "Côte d’Ivoire", code: "CI", phone: "+225" },
  { name: "Croatia", code: "HR", phone: "+385" },
  { name: "Cuba", code: "CU", phone: "+53" },
  { name: "Curaçao", code: "CW", phone: "+599" },
  { name: "Cyprus", code: "CY", phone: "+357" },
  { name: "Czechia", code: "CZ", phone: "+420" },
  { name: "Denmark", code: "DK", phone: "+45" },
  { name: "Djibouti", code: "DJ", phone: "+253" },
  { name: "Dominica", code: "DM", phone: "+1" },
  { name: "Dominican Republic", code: "DO", phone: "+1" },
  { name: "Ecuador", code: "EC", phone: "+593" },
  { name: "Egypt", code: "EG", phone: "+20" },
  { name: "El Salvador", code: "SV", phone: "+503" },
  { name: "Equatorial Guinea", code: "GQ", phone: "+240" },
  { name: "Eritrea", code: "ER", phone: "+291" },
  { name: "Estonia", code: "EE", phone: "+372" },
  { name: "Ethiopia", code: "ET", phone: "+251" },
  { name: "Falkland Islands (Islas Malvinas)", code: "FK", phone: "+500" },
  { name: "Faroe Islands", code: "FO", phone: "+298" },
  { name: "Fiji", code: "FJ", phone: "+679" },
  { name: "Finland", code: "FI", phone: "+358" },
  { name: "France", code: "FR", phone: "+33" },
  { name: "French Guiana", code: "GF", phone: "+594" },
  { name: "French Polynesia", code: "PF", phone: "+689" },
  { name: "French Southern Territories", code: "TF", phone: "" },
  { name: "Gabon", code: "GA", phone: "+241" },
  { name: "Gambia", code: "GM", phone: "+220" },
  { name: "Georgia", code: "GE", phone: "+995" },
  { name: "Germany", code: "DE", phone: "+49" },
  { name: "Ghana", code: "GH", phone: "+233" },
  { name: "Gibraltar", code: "GI", phone: "+350" },
  { name: "Greece", code: "GR", phone: "+30" },
  { name: "Greenland", code: "GL", phone: "+299" },
  { name: "Grenada", code: "GD", phone: "+1" },
  { name: "Guadeloupe", code: "GP", phone: "+590" },
  { name: "Guam", code: "GU", phone: "+1" },
  { name: "Guatemala", code: "GT", phone: "+502" },
  { name: "Guernsey", code: "GG", phone: "+44" },
  { name: "Guinea", code: "GN", phone: "+224" },
  { name: "Guinea-Bissau", code: "GW", phone: "+245" },
  { name: "Guyana", code: "GY", phone: "+592" },
  { name: "Haiti", code: "HT", phone: "+509" },
  { name: "Heard & McDonald Islands", code: "HM", phone: "+61" },
  { name: "Vatican City", code: "VA", phone: "+39" },
  { name: "Honduras", code: "HN", phone: "+504" },
  { name: "Hong Kong", code: "HK", phone: "+852" },
  { name: "Hungary", code: "HU", phone: "+36" },
  { name: "Iceland", code: "IS", phone: "+354" },
  { name: "India", code: "IN", phone: "+91" },
  { name: "Indonesia", code: "ID", phone: "+62" },
  { name: "Iran", code: "IR", phone: "+98" },
  { name: "Iraq", code: "IQ", phone: "+964" },
  { name: "Ireland", code: "IE", phone: "+353" },
  { name: "Isle of Man", code: "IM", phone: "+44" },
  { name: "Israel", code: "IL", phone: "+972" },
  { name: "Italy", code: "IT", phone: "+39" },
  { name: "Jamaica", code: "JM", phone: "+1" },
  { name: "Japan", code: "JP", phone: "+81" },
  { name: "Jersey", code: "JE", phone: "+44" },
  { name: "Jordan", code: "JO", phone: "+962" },
  { name: "Kazakhstan", code: "KZ", phone: "+7" },
  { name: "Kenya", code: "KE", phone: "+254" },
  { name: "Kiribati", code: "KI", phone: "+686" },
  { name: "North Korea", code: "KP", phone: "+850" },
  { name: "South Korea", code: "KR", phone: "+82" },
  { name: "Kosovo", code: "XK", phone: "+383" },
  { name: "Kuwait", code: "KW", phone: "+965" },
  { name: "Kyrgyzstan", code: "KG", phone: "+996" },
  { name: "Laos", code: "LA", phone: "+856" },
  { name: "Latvia", code: "LV", phone: "+371" },
  { name: "Lebanon", code: "LB", phone: "+961" },
  { name: "Lesotho", code: "LS", phone: "+266" },
  { name: "Liberia", code: "LR", phone: "+231" },
  { name: "Libya", code: "LY", phone: "+218" },
  { name: "Liechtenstein", code: "LI", phone: "+423" },
  { name: "Lithuania", code: "LT", phone: "+370" },
  { name: "Luxembourg", code: "LU", phone: "+352" },
  { name: "Macao", code: "MO", phone: "+853" },
  { name: "North Macedonia", code: "MK", phone: "+389" },
  { name: "Madagascar", code: "MG", phone: "+261" },
  { name: "Malawi", code: "MW", phone: "+265" },
  { name: "Malaysia", code: "MY", phone: "+60" },
  { name: "Maldives", code: "MV", phone: "+960" },
  { name: "Mali", code: "ML", phone: "+223" },
  { name: "Malta", code: "MT", phone: "+356" },
  { name: "Marshall Islands", code: "MH", phone: "+692" },
  { name: "Martinique", code: "MQ", phone: "+596" },
  { name: "Mauritania", code: "MR", phone: "+222" },
  { name: "Mauritius", code: "MU", phone: "+230" },
  { name: "Mayotte", code: "YT", phone: "+262" },
  { name: "Mexico", code: "MX", phone: "+52" },
  { name: "Micronesia", code: "FM", phone: "+691" },
  { name: "Moldova", code: "MD", phone: "+373" },
  { name: "Monaco", code: "MC", phone: "+377" },
  { name: "Mongolia", code: "MN", phone: "+976" },
  { name: "Montenegro", code: "ME", phone: "+382" },
  { name: "Montserrat", code: "MS", phone: "+1" },
  { name: "Morocco", code: "MA", phone: "+212" },
  { name: "Mozambique", code: "MZ", phone: "+258" },
  { name: "Myanmar (Burma)", code: "MM", phone: "+95" },
  { name: "Namibia", code: "NA", phone: "+264" },
  { name: "Nauru", code: "NR", phone: "+674" },
  { name: "Nepal", code: "NP", phone: "+977" },
  { name: "Netherlands", code: "NL", phone: "+31" },
  { name: "Curaçao", code: "AN", phone: "+599" },
  { name: "New Caledonia", code: "NC", phone: "+687" },
  { name: "New Zealand", code: "NZ", phone: "+64" },
  { name: "Nicaragua", code: "NI", phone: "+505" },
  { name: "Niger", code: "NE", phone: "+227" },
  { name: "Nigeria", code: "NG", phone: "+234" },
  { name: "Niue", code: "NU", phone: "+683" },
  { name: "Norfolk Island", code: "NF", phone: "+672" },
  { name: "Northern Mariana Islands", code: "MP", phone: "+1" },
  { name: "Norway", code: "NO", phone: "+47" },
  { name: "Oman", code: "OM", phone: "+968" },
  { name: "Pakistan", code: "PK", phone: "+92" },
  { name: "Palau", code: "PW", phone: "+680" },
  { name: "Palestine", code: "PS", phone: "+970" },
  { name: "Panama", code: "PA", phone: "+507" },
  { name: "Papua New Guinea", code: "PG", phone: "+675" },
  { name: "Paraguay", code: "PY", phone: "+595" },
  { name: "Peru", code: "PE", phone: "+51" },
  { name: "Philippines", code: "PH", phone: "+63" },
  { name: "Pitcairn Islands", code: "PN", phone: "+64" },
  { name: "Poland", code: "PL", phone: "+48" },
  { name: "Portugal", code: "PT", phone: "+351" },
  { name: "Puerto Rico", code: "PR", phone: "+1" },
  { name: "Qatar", code: "QA", phone: "+974" },
  { name: "Réunion", code: "RE", phone: "+262" },
  { name: "Romania", code: "RO", phone: "+40" },
  { name: "Russia", code: "RU", phone: "+7" },
  { name: "Rwanda", code: "RW", phone: "+250" },
  { name: "St. Barthélemy", code: "BL", phone: "+590" },
  { name: "St. Helena", code: "SH", phone: "+290" },
  { name: "St. Kitts & Nevis", code: "KN", phone: "+1" },
  { name: "St. Lucia", code: "LC", phone: "+1" },
  { name: "St. Martin", code: "MF", phone: "+590" },
  { name: "St. Pierre & Miquelon", code: "PM", phone: "+508" },
  { name: "St. Vincent & Grenadines", code: "VC", phone: "+1" },
  { name: "Samoa", code: "WS", phone: "+685" },
  { name: "San Marino", code: "SM", phone: "+378" },
  { name: "São Tomé & Príncipe", code: "ST", phone: "+239" },
  { name: "Saudi Arabia", code: "SA", phone: "+966" },
  { name: "Senegal", code: "SN", phone: "+221" },
  { name: "Serbia", code: "RS", phone: "+381" },
  {
    code: "CS",
    name: "Serbia",
    phone: "+381",
  },
  { name: "Seychelles", code: "SC", phone: "+248" },
  { name: "Sierra Leone", code: "SL", phone: "+232" },
  { name: "Singapore", code: "SG", phone: "+65" },
  { name: "Sint Maarten", code: "SX", phone: "+1" },
  { name: "Slovakia", code: "SK", phone: "+421" },
  { name: "Slovenia", code: "SI", phone: "+386" },
  { name: "Solomon Islands", code: "SB", phone: "+677" },
  { name: "Somalia", code: "SO", phone: "+252" },
  { name: "South Africa", code: "ZA", phone: "+27" },
  { name: "South Georgia & South Sandwich Islands", code: "GS", phone: "+500" },
  { name: "South Sudan", code: "SS", phone: "+211" },
  { name: "Spain", code: "ES", phone: "+34" },
  { name: "Sri Lanka", code: "LK", phone: "+94" },
  { name: "Sudan", code: "SD", phone: "+249" },
  { name: "Suriname", code: "SR", phone: "+597" },
  { name: "Svalbard & Jan Mayen", code: "SJ", phone: "+47" },
  { name: "Sweden", code: "SE", phone: "+46" },
  { name: "Switzerland", code: "CH", phone: "+41" },
  { name: "Syria", code: "SY", phone: "+963" },
  { name: "Taiwan", code: "TW", phone: "+886" },
  { name: "Tajikistan", code: "TJ", phone: "+992" },
  { name: "Tanzania", code: "TZ", phone: "+255" },
  { name: "Thailand", code: "TH", phone: "+66" },
  { name: "Timor-Leste", code: "TL", phone: "+670" },
  { name: "Togo", code: "TG", phone: "+228" },
  { name: "Tokelau", code: "TK", phone: "+690" },
  { name: "Tonga", code: "TO", phone: "+676" },
  { name: "Trinidad & Tobago", code: "TT", phone: "+1" },
  { name: "Tunisia", code: "TN", phone: "+216" },
  { name: "Turkey", code: "TR", phone: "+90" },
  { name: "Turkmenistan", code: "TM", phone: "+993" },
  { name: "Turks & Caicos Islands", code: "TC", phone: "+1" },
  { name: "Tuvalu", code: "TV", phone: "+688" },
  { name: "Uganda", code: "UG", phone: "+256" },
  { name: "Ukraine", code: "UA", phone: "+380" },
  { name: "United Arab Emirates", code: "AE", phone: "+971" },
  { name: "United Kingdom", code: "GB", phone: "+44" },
  { name: "United States", code: "US", phone: "+1" },
  { name: "Uruguay", code: "UY", phone: "+598" },
  { name: "U.S. Outlying Islands", code: "UM", phone: "+011" },
  { name: "U.S. Virgin Islands", code: "VI", phone: "+1" },
  { name: "Uzbekistan", code: "UZ", phone: "+998" },
  { name: "Vanuatu", code: "VU", phone: "+678" },
  { name: "Venezuela", code: "VE", phone: "+58" },
  { name: "Vietnam", code: "VN", phone: "+84" },
  { name: "Wallis & Futuna", code: "WF", phone: "+681" },
  { name: "Western Sahara", code: "EH", phone: "+212" },
  { name: "Yemen", code: "YE", phone: "+967" },
  { name: "Zambia", code: "ZM", phone: "+260" },
  { name: "Zimbabwe", code: "ZW", phone: "+263" },
];
export const itemType = [
  { key: "standard", value: "standard", label: "Standard" },
  { key: "privilized", value: "privilized", label: "Privilized" },
];

export const daysOptions = [
  { key: 1, value: "1 day" },
  { key: 7, value: "1 week" },
  { key: 30, value: "Month" },
  { key: 90, value: "3 Months" },
  { key: 180, value: "6 Months" },
  { key: 0, value: "custom" },
  { key: 999, value: "Till Date" },
];
